<!--
 * @Description: 报警历史
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-25 09:06:10
 * @LastEditors: 支磊
 * @LastEditTime: 2021-11-23 15:09:24
-->
<template>
  <div class="historylist">
    <div class="select">
      <el-input
        style="width: 450px"
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        size="small"
        v-model="searchString"
      >
      </el-input>
      <!-- <AddNewBtn @add="filterF" icon="el-icon-s-operation" text="筛选" /> -->
    </div>
    <div class="table">
      <el-table
        :data="tableData"
        style="width: 100%"
        height="100%"
        @row-click="rowClickF"
      >
        <el-table-column prop="ruleName" label="设备名称"> </el-table-column>
        <el-table-column align="center" prop="deviceFullDept" label="设备路径">
        </el-table-column>
        <el-table-column align="center" prop="pointName" label="测点名称">
        </el-table-column>
        <el-table-column align="center" label="报警类型" width="180">
          <template slot-scope="scope">
            {{ scope.row.rule | ruleType }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="报警等级">
          <template slot-scope="scope">
            {{ statusArr[scope.row.status] }}
          </template>
        </el-table-column>

        <el-table-column align="center" prop="axial" label="轴向">
          <template slot-scope="scope">
            {{ axial[scope.row.axial] }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="特征值">
          <template slot-scope="scope">
            {{ scope.row.eigenvalue | eigen }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="报警时间">
          <template slot-scope="scope">
            {{ scope.row.alarmTime | time }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            {{ scope.row.processStatus == 0 ? "未处理" : "已处理" }}
          </template>
        </el-table-column>
        ><el-table-column align="center" width="120px" label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.processStatus == 0"
              type="text"
              @click.stop="clickF(scope)"
              >通过</el-button
            >
          </template>
        </el-table-column>
        <template slot="empty">
          <div>
            <img style="width: 96px" src="/img/notData.png" alt="" />
            <p style="color: #909399; font-size: 14px; text-align: center">
              暂无数据
            </p>
          </div>
        </template>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 筛选面板 -->
    <filter-dialog v-show="filterS" @close="closeF"></filter-dialog>
    <!-- 筛选面板 end -->
  </div>
</template>

<script>
import filterDialog from "./private/historylistFiltersComponents";
import { filterList, filterTree, filter, alarmupdate } from "@/api/rule.js";
export default {
  name: "historylist",
  components: { filterDialog },
  data() {
    return {
      statusArr: ["正常", "低报", "高报", "高高报"],
      axial: [0, "x(A)", "y(B)", "z(C)"],
      searchString: "",
      pageSize: 10,
      currentPage: 1,
      tableData: [],
      total: 0,
      timeF: null,
      filterS: false, //筛选面板显示状态
    };
  },
  filters: {
    time(data) {
      let time = "";
      let format = "yyyy-MM-dd HH:mm:ss";
      var t = new Date(data);
      var tf = function (i) {
        return (i < 10 ? "0" : "") + i;
      };
      time = format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
        let date = "";
        switch (a) {
          case "yyyy":
            date = tf(t.getFullYear());
            break;
          case "MM":
            date = tf(t.getMonth() + 1);
            break;
          case "mm":
            date = tf(t.getMinutes());
            break;
          case "dd":
            date = tf(t.getDate());
            break;
          case "HH":
            date = tf(t.getHours());
            break;
          case "ss":
            date = tf(t.getSeconds());
            break;
        }
        return date;
      });
      return time;
    },
    ruleType(data) {
      let text = "幅值";
      switch (data) {
        case 1:
          text = "幅值";
          break;
        case 2:
          text = "频谱";
          break;
        case 3:
          text = "突变";
          break;
        default:
          break;
      }
      return text;
    },
    eigen(data) {
      let text = "加速度峰值";
      switch (data) {
        case 1:
          text = "加速度峰值";
          break;
        case 2:
          text = "速度均方根";
          break;
        case 3:
          text = "电压";
          break;
        case 4:
          text = "温度";
          break;
        default:
          break;
      }
      return text;
    },
  },
  computed: {
    deviceIds() {
      return sessionStorage.getItem("deviceIds");
    },
  },
  //监听执行
  watch: {
    searchString() {
      clearTimeout(this.timeF);
      this.timeF = setTimeout(() => {
        this.currentPage = 1;
        this.getData();
      }, 1200);
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    clickF(data) {
      this.$prompt("处理意见", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        inputPattern: /\S/,
        inputErrorMessage: "处理意见不能为空",
      })
        .then(({ value }) => {
          this.alarmupdate(value, data.row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消处理",
          });
        });
    },
    alarmupdate(data, id) {
      let apidata = {
        id: id,
        processOpinion: data,
      };
      alarmupdate(apidata).then((res) => {
        if (this.apiReply(res)) {
          this.getData();
        }
      });
    },
    rowClickF(data) {
      this.toPath(
        "/equipment/devience?deviceId=" +
          data.deviceId +
          "&deviceName=" +
          data.deviceName
      );
    },
    getfilterTreeF() {
      // 获取过滤时用到的树结构信息
      filterTree().then((res) => {
        if (this.apiReply(res)) {
          console.log(res);
        }
      });
    },
    getfilterF() {
      // 获取可以过滤的数据
      filter().then((res) => {
        if (this.apiReply(res)) {
          console.log("filter:", res);
        }
      });
    },
    closeF(data) {
      // 关闭筛选弹窗
      this.filterS = false;
    },
    filterF() {
      //  筛选
      console.log("筛选");
      this.filterS = true;
    },
    getData() {
      this.tableData = [];
      let data = {
        searchString: this.searchString,
        pageNo: this.currentPage,
        pageSize: this.pageSize,
      };
      filterList(data).then((res) => {
        if (this.apiReply(res)) {
          this.tableData = this.tableData.concat(res.data.data.dataList);
          console.log(this.tableData);
          let arr = [];
          if (this.deviceIds) {
            arr = this.deviceIds.split(",");
          }
          console.log("ids", arr);
          this.tableData = this.tableData.filter((item) => {
            if (arr.includes(item.deviceId + "")) {
              return true;
            }
          });

          this.total = res.data.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.historylist {
  height: 100%;
  padding: 20px 44px 24px;
  .select {
    display: flex;
    justify-content: space-between;
  }
  .table {
    height: calc(100% - 92px);
    overflow: auto;
    padding-top: 10px;
  }
  .page {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .delete {
    cursor: pointer;
    color: $delete_color;
  }
}
</style>

<!--
 * @Description: 筛选面板
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-28 09:01:05
 * @LastEditors: 支磊
 * @LastEditTime: 2021-05-28 10:33:37
-->
<template>
  <div class="historylistFiltersComponents">
    <div class="model"></div>
    <div class="main">
      <span class="close el-icon el-icon-close" @click="close"></span>
      <div class="title">筛选</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "historylistFiltersComponents",
  components: {},
  data() {
    return {};
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {},
  methods: {
    close(data) {
      this.$emit("close", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.historylistFiltersComponents {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  .model {
    position: absolute;
    @extend .model;
  }
  .main {
    padding: 28px;
    width: 800px;
    height: 286px;
    background: #fff;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    .close {
      color: #909399;
      font-size: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 9;
    }
    .title {
      @extend .title_green;
    }
  }
}
</style>
